import { Box, Image, Flex, Heading, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { shortCourses } from '../../constants/shortCourses'
import RedStar from '../../assets/red-star.svg'
import { APPLICATION_MAX_WIDTH } from '../../constants'
import ShortCourseCard from './ShortCourseCard'

export function ShortCoursesSection({
  headerTextAlign = 'left',
}: {
  headerTextAlign?: 'center' | 'left' | 'right'
}): ReactElement {
  return (
    <Box
      padding="8"
      data-testid="short-courses-section"
      width="100%"
      maxWidth={APPLICATION_MAX_WIDTH}
      margin="auto"
      as="section"
    >
      <Box position="absolute" right={{ base: '0', lg: '10' }}>
        <Image src={RedStar} width={{ base: '50%' }} alt="red star" />
      </Box>
      <Box paddingY={5} marginTop={5} textAlign={headerTextAlign}>
        <Heading
          as="h2"
          color="primary.500"
          fontSize={{ base: '3xl', lg: '5xl' }}
        >
          Short Courses
        </Heading>
        <Text fontSize={{ base: 'md' }} color="primary.500">
          Enrol for our short courses and learn in-demand skills for less than
          half the normal duration and cost.
        </Text>
      </Box>

      <Flex
        direction="row"
        flexWrap="nowrap"
        gap="4"
        py={8}
        width="100%"
        overflowX="scroll"
      >
        {shortCourses.map(programme => (
          <ShortCourseCard
            key={programme.title}
            title={programme.title}
            description={programme.description}
            image={programme.image}
            learningOutcomes={programme.learningOutcomes}
            link={programme.link}
            duration={programme.duration}
          />
        ))}
      </Flex>
    </Box>
  )
}
