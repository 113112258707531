import { UseToastOptions } from "@chakra-ui/react";

export const errorToast: UseToastOptions = {
    duration: 30000,
    isClosable: true,
    position: 'top',
    status: 'error',
    title: 'An unexpected error occurred',
    description:
      'Please try again. Contact support@dotcampus.co if error persists',
    variant: 'error',
  }
  