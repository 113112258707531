import { useToast } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import {
  DCLC_BACKEND_API_KEY,
  DCLC_BACKEND_API_URL,
  HAS_SUBMITTED_APPLICATION_FORM,
} from '../../constants'
import {
  ApplicationFormValues,
  RegistrationPaymentInfo,
  SubmissionPayload,
} from '../../types'
import { errorToast, setSessionStorage } from '../../utils'
import { payWithFlutterwave } from '../../utils/payWithFlutterwave'
import { payWithPaystack } from '../../utils/payWithPaystack'

const isPaystackPayment = (preferredPaymentCurrency: string): boolean =>
  preferredPaymentCurrency.toLowerCase() === 'ngn'

export async function handleFormSubmissionToBackend(
  values: SubmissionPayload,
): Promise<void> {
  const payload = JSON.stringify(values)

  await fetch(`${DCLC_BACKEND_API_URL}/registrations`, {
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${DCLC_BACKEND_API_KEY}`,
    },
  })
}

export async function submitData(
  values: SubmissionPayload,
  setOnSuccess: Dispatch<SetStateAction<boolean>>,
  toast: ReturnType<typeof useToast>,
): Promise<void> {
  // remove payNow from values
  delete values.payNow

  try {
    await handleFormSubmissionToBackend(values)

    setOnSuccess(true)
    setSessionStorage(
      HAS_SUBMITTED_APPLICATION_FORM,
      HAS_SUBMITTED_APPLICATION_FORM,
    )
  } catch (error) {
    console.error(error)
    toast(errorToast)
  }
}

export const handleRegistrationPayment = (
  values: ApplicationFormValues,
  paymentDetails: RegistrationPaymentInfo,
  setOnSuccess: Dispatch<SetStateAction<boolean>>,
  toast: ReturnType<typeof useToast>,
): void => {
  if (isPaystackPayment(values.preferredPaymentCurrency)) {
    payWithPaystack({
      email: values.email,
      amount: paymentDetails.discountedPrice,
      paymentReference: paymentDetails.transactionReference,
      planCode: paymentDetails.planCode as string,

      handleClose: /* istanbul ignore next */ async () => {
        await submitData(
          {
            ...values,
            ...paymentDetails,
          },
          setOnSuccess,
          toast,
        )
      },
      handleCallback: /* istanbul ignore next */ async ({ reference }) => {
        await submitData(
          {
            ...values,
            ...paymentDetails,
            paymentReference: reference,
          },
          setOnSuccess,
          toast,
        )
      },
    })
  } else {
    payWithFlutterwave({
      email: values.email,
      amount: paymentDetails.discountedPrice as number,
      paymentReference: paymentDetails.transactionReference,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      currency: values.preferredPaymentCurrency,
      planCode: paymentDetails.planCode as number,

      handleClose: /* istanbul ignore next */ async () => {
        await submitData(
          {
            ...values,
            ...paymentDetails,
          },
          setOnSuccess,
          toast,
        )
      },

      handleCallback: /* istanbul ignore next */ async ({
        tx_ref: reference,
        transaction_id: transactionId,
      }) => {
        await submitData(
          {
            ...values,
            ...paymentDetails,
            paymentReference: reference,
            transactionId,
          },
          setOnSuccess,
          toast,
        )
      },
    })
  }
}

export const handleApplicationSubmission = (
  setLoading: Dispatch<SetStateAction<boolean>>,
  setOnSuccess: Dispatch<SetStateAction<boolean>>,
  toast: ReturnType<typeof useToast>,
  values: ApplicationFormValues,
  paymentDetails: RegistrationPaymentInfo | null,
): void => {
  if (!paymentDetails) return
  setLoading(true)
  try {
    handleRegistrationPayment(values, paymentDetails, setOnSuccess, toast)
  } catch (error) {
    toast(errorToast)
  }
}
