/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  HStack,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react'

import { FormFieldWrapper, FormFieldWrapperProps } from './FormFieldWrapper'

export type InputType = 'email' | 'number' | 'phone' | 'text' | 'date'

export type InputProps = FormFieldWrapperProps &
  Omit<ChakraInputProps, 'type'> & {
    type?: InputType
  }

/**
 * For inputs of the given type, the following characters will be stripped
 * during the `onChange` event (thus ignoring them).
 */
export const ignoredCharacterSets: Partial<Record<InputType, RegExp>> = {
  number: /[^0-9.]+/g,
  phone: /[^0-9.\-()+ ]+/g,
}

/**
 * A generic <Input /> element used in forms throughout the application.
 */
export const Input: React.FC<InputProps> = props => {
  // Destructuring `isRequired` to prevent the nasty react console error about
  // this attribute not being supported (see the ...rest spread below).
  const {
    error,
    isRequired,
    name,
    type,
    placeholder,
    onChange,
    secondaryLabel,
    tooltipMessage,
    autoComplete,
    ...rest
  } = props

  return (
    <FormFieldWrapper labelColor="gray.700" {...props}>
      <HStack align="center">
        <ChakraInput
          data-testid={`${name}-input`}
          width="full"
          color="gray.700"
          {...rest}
          autoComplete={autoComplete ?? 'on'}
          backgroundColor="gray.200"
          borderRadius="12"
          p={{ base: '2', lg: '2.5' }}
          border="1.5px solid"
          borderColor="gray.300"
          fontSize="md"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          outline="none"
          type={type}
        />
      </HStack>
    </FormFieldWrapper>
  )
}
