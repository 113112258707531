import { Flex, Box } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ToggleType } from '../../types'

export const Switch = ({ isToggled, onToggle }: ToggleType): ReactElement => {
  return (
    <>
      <Flex
        w={16}
        h={8}
        bgColor={isToggled ? 'primary.500' : 'red.300'}
        rounded="full"
        onClick={onToggle}
        data-testid="switch"
        role="switch"
        aria-checked={isToggled ? 'true' : 'false'}
        tabIndex={0}
        id="mySwitch"
        aria-label="change currency to usd"
      >
        <Box
          as="span"
          w={8}
          h={8}
          bgColor={'gray.100'}
          borderRadius="50%"
          ml={isToggled ? '8' : '0'}
          data-testid="switchButton"
        />
      </Flex>
    </>
  )
}
