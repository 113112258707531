import { countries } from './countries'
import { learningInterestChoices } from './options'
import {
  employmentOptions,
  learningPreferences,
  paymentCurrencies,
  paymentPlans,
  referredByOptions,
} from './selectOptions'
import {
  StepOneValidationSchema,
  StepTwoValidationSchema,
} from './validationSchema'

export const stepConfig = [
  {
    heading: 'Personal Information',
    validationSchema: StepOneValidationSchema,
    fields: [
      {
        name: 'firstName',
        label: 'First Name',
        type: 'text',
        isRequired: true,
        placeholder: 'Enter first name',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        type: 'text',
        isRequired: true,
        placeholder: 'Enter last name',
      },
      {
        name: 'email',
        label: 'Email',
        type: 'email',
        isRequired: true,
        placeholder: 'Enter email address',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        type: 'phone',
        isRequired: true,
        placeholder: 'Enter phone number',
      },
      {
        name: 'countryOfResidence',
        label: 'Country of Residence',
        type: 'select',
        choices: countries.map(c => ({ label: c.name, value: c.name })),
        isRequired: true,
      },
      {
        name: 'employmentStatus',
        label: 'Employment Status',
        type: 'select',
        choices: employmentOptions,
        isRequired: true,
      },
    ],
  },
  {
    heading: 'Payment Details',
    validationSchema: StepTwoValidationSchema,
    fields: [
      {
        name: 'learningInterest',
        label: 'Programme of Interest',
        type: 'select',
        choices: learningInterestChoices,
        isRequired: true,
      },
      {
        name: 'preferredPaymentCurrency',
        label: 'Preferred Payment Currency',
        type: 'select',
        choices: paymentCurrencies,
        isRequired: true,
      },
      {
        name: 'learningPreference',
        label: 'Learning Preference',
        type: 'select',
        choices: learningPreferences,
        isRequired: true,
      },
      {
        name: 'paymentPlan',
        label: 'Payment Plan',
        type: 'select',
        choices: paymentPlans,
        isRequired: true,
      },
      {
        name: 'referredBy',
        label: 'How did you learn about us?',
        type: 'select',
        choices: referredByOptions,
        isRequired: true,
      },
      {
        name: 'referralCode',
        label: 'Referral Code',
        type: 'text',
        placeholder: 'Enter code',
      },
    ],
  },
  {
    heading: 'Review',
    validationSchema: StepOneValidationSchema.concat(StepTwoValidationSchema),
    fields: [],
  },
]
