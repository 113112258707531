import { Box, Text } from '@chakra-ui/react'
import React from 'react'

export const FormSuccess: React.FC = () => {
  return (
    <Box
      data-testid="application-success"
      paddingX={{ base: '12', md: '24' }}
      margin="auto"
      textAlign="center"
    >
      <Text color="primary.500" fontSize="2xl" my="3">
        Registration was successful. Please check your email for the next steps.
        Thank you!
      </Text>
      <Text>NB: Remember to check the your email spam/junk folders also.</Text>
    </Box>
  )
}
