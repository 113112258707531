export const formatString = (string: string): string =>
  string
    .replace(/([A-Z])/g, ' $1') // Add space before capital letters
    .replace(/(^\w|\s\w)/g, str => str.toUpperCase()) // Capitalize the first letter of each word

export const formatKey = (key: string): string =>
  formatString(key).toUpperCase()

export const formatValue = (key: string, value: string): string | undefined => {
  switch (key) {
    case 'email':
      return value
    case 'referralCode':
    case 'preferredPaymentCurrency':
      return value.toUpperCase()
    case 'referredBy':
      return value[0].toUpperCase() + value.slice(1)
    default:
      return formatString(value) || '-'
  }
}
