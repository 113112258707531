import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { RegistrationPaymentInfo } from '../../types'
import { formatKey, formatValue } from '../../utils/formatString'
import { initialValues } from './ApplicationForm'

interface FormReviewProps {
  formValues: typeof initialValues
  paymentDetails: RegistrationPaymentInfo | null
}

export const FormReviewComponent: React.FC<{
  sectionTitle: string
  formValues: typeof initialValues
  formItems: Array<keyof typeof initialValues>
}> = ({ formValues, formItems, sectionTitle }) => (
  <Box
    borderRadius="14px"
    py="5"
    px={{ base: '5', lg: '10' }}
    bgColor="gray.250"
    mb="4"
  >
    <Text textTransform="uppercase" mb="4" fontWeight="semibold" fontSize="sm">
      {sectionTitle}
    </Text>
    {formItems.map(formItem => (
      <Flex
        fontSize="sm"
        py={2}
        key={formItem}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="gray.600" fontSize="sm" fontWeight="semibold">
          {formatKey(formItem)}
        </Text>
        <Text color="gray.700" fontWeight="semibold">
          {formatValue(formItem, formValues[formItem])}
        </Text>
      </Flex>
    ))}
  </Box>
)

export const FormReview: React.FC<FormReviewProps> = ({
  formValues,
  paymentDetails,
}) => {
  const personalInfo: Array<keyof typeof initialValues> = [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'countryOfResidence',
    'employmentStatus',
  ]

  const paymentInfo: Array<keyof typeof initialValues> = [
    'learningPreference',
    'learningInterest',
    'preferredPaymentCurrency',
    'paymentPlan',
    'referredBy',
    'referralCode',
  ]
  if (!paymentDetails) return null
  return (
    <Box>
      <Box
        borderRadius="14px"
        py="5"
        px={{ base: '5', lg: '10' }}
        bgColor="gray.250"
        mb="4"
      >
        <Text
          textTransform="uppercase"
          mb="4"
          fontWeight="semibold"
          fontSize="sm"
        >
          Amount To Be Paid
        </Text>
        <Text color="gray.700" fontWeight="semibold" fontSize="lg">
          {Intl.NumberFormat('en-ng', {
            style: 'currency',
            currency: formValues.preferredPaymentCurrency,
          }).format(paymentDetails.discountedPrice)}
        </Text>
        <Text color="gray.600" textDecoration="line-through">
          {Intl.NumberFormat('en-ng', {
            style: 'currency',
            currency: formValues.preferredPaymentCurrency,
          }).format(paymentDetails.actualPrice)}
        </Text>
        <Text color="gray.700" mt="5" fontSize="sm" fontWeight="semibold">
          {paymentDetails.paymentNote}
        </Text>
      </Box>
      <FormReviewComponent
        sectionTitle="Personal Information"
        formValues={formValues}
        formItems={personalInfo}
      />
      <FormReviewComponent
        sectionTitle="Program Details"
        formValues={formValues}
        formItems={paymentInfo}
      />
    </Box>
  )
}
