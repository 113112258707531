import React from 'react'
import { Outlet } from 'react-router-dom'

import { BaseLayout } from './BaseLayout'
import { LayoutProps } from './types'
import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react'
import students from '../assets/students.webp'
import BlackLogo from '../assets/black-logo.png'

export const PublicFormLayout: React.FC<LayoutProps> = ({
  'data-testid': tid,
}) => {
  return (
    <BaseLayout fullWidth data-testid={tid}>
      <Flex marginX="auto" width={{ base: '100%' }}>
        <Grid
          alignContent={{ base: 'stretch', lg: 'center' }}
          display={{ base: 'none', md: 'block' }}
          width={{ base: '100%', md: '45%' }}
          bg="orange.600"
        >
          <Box textAlign="center" mt={{ base: '24%', lg: '0' }}>
            <Image src={students} alt="Student learning tech skills" />
            <Box width="75%" marginX="auto">
              <Heading
                as="h3"
                my="12"
                lineHeight="1"
                fontWeight="bold"
                marginBottom="5"
                textAlign="center"
                color="black"
                textTransform="uppercase"
              >
                Excel in tech with expert mentorship
              </Heading>
              <Text fontSize={{ base: 'md', lg: 'lg' }} color="black">
                We empower individuals worldwide, especially in Africa, by
                offering accessible learning, mentorship, and resources for
                personal and professional growth.
              </Text>
            </Box>
          </Box>
        </Grid>
        <Box width={{ base: '100%', md: '55%' }} margin="auto">
          <Image
            display={{ base: 'block', md: 'none' }}
            src={BlackLogo}
            my="3"
            alt="Dot Campus Logo Black"
            width="320px"
            marginX="auto"
          />
          <Outlet />
        </Box>
      </Flex>
    </BaseLayout>
  )
}
