import { Box, Heading, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

export const SectionTitle = ({
  titlePart1,
  titlePart2,
  description,
}: {
  titlePart1: string
  titlePart2: string
  description: string
}): ReactElement => {
  return (
    <Box paddingY={5} marginTop={5}>
      <Heading as="h2" color="gray.900" fontSize={{ base: '3xl', lg: '5xl' }}>
        {titlePart1} <br /> {titlePart2}
      </Heading>
      <Text fontSize={{ base: 'md', lg: 'lg' }} color="primary.500">
        {description}
      </Text>
    </Box>
  )
}
