import { useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Button, Grid, Heading, Text, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { HAS_SUBMITTED_APPLICATION_FORM } from '../../constants'
import { Input, InputType, Select } from '../Input'
import { handleApplicationSubmission } from './handleApplicationSubmission'
import { getPaymentDetails } from '../../utils/getPaymentDetails'
import { getLearningPath } from '../../utils/getLearningPath'
import { getSessionStorageBoolean } from '../../utils'
import { FormValues, RegistrationPaymentInfo } from '../../types'
import { FormClosed } from './FormClosed'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { stepConfig } from '../../constants/formElements'
import { FormReview } from './FormReview'

export const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  countryOfResidence: '',
  phoneNumber: '',
  employmentStatus: '',
  learningPreference: '',
  learningInterest: '',
  preferredPaymentCurrency: '',
  paymentPlan: '',
  referredBy: '',
  referralCode: '',
}

export const ApplicationForm: React.FC = () => {
  const [step, setStep] = useState(0)
  const [error, setError] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [onSuccess, setOnSuccess] = useState(false)
  const [paymentDetails, setPaymentDetails] =
    useState<RegistrationPaymentInfo | null>(null)

  const toast = useToast()

  const currentStep = useMemo(() => stepConfig[step], [step])

  const handlePrevStep = useCallback(() => {
    setStep(prev => prev - 1)
    setError('')
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: currentStep.validationSchema,
    validateOnBlur: false, // Validate fields when blurred
    onSubmit: async values => {
      if (step === 1) {
        setLoading(true)
        const data = await getPaymentDetails({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          learningInterest: values.learningInterest,
          learningPreference: values.learningPreference,
          paymentPlan: values.paymentPlan,
          paymentCurrency: values.preferredPaymentCurrency,
          referralCode: values.referralCode,
        })
        setLoading(false)
        if (!data) {
          setError(
            'An error occurred while fetching payment details. Please try again or contact us if the issue persists.',
          )
          return
        }
        sessionStorage.setItem('paymentDetails', JSON.stringify(data))
        localStorage.setItem('USER', JSON.stringify(values))
        setPaymentDetails(data)
        setStep(prev => prev + 1)
        formik.setTouched({})
        return
      }

      if (step === 2) {
        setLoading(true)
        const learningPath = getLearningPath(values.learningInterest)
        return handleApplicationSubmission(
          setLoading,
          setOnSuccess,
          toast,
          { ...values, learningPath, payNow: true },
          paymentDetails,
        )
      }
      setStep(prev => prev + 1)
      formik.setTouched({}) // Reset touched fields
    },
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const allParams = Array.from(urlParams.keys())
    const params = {
      learningPreference: urlParams.get('learningPreference'),
      paymentPlan: urlParams.get('paymentPlan'),
      referralCode: urlParams.get('rf'),
      learningInterest: urlParams.get('course'),
    }

    // Set only the fields that have valid values
    allParams.forEach(param => {
      if (param in formik.values) {
        formik.setFieldValue(param, urlParams.get(param))
      }
    })
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        formik.setFieldValue(key, value)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (onSuccess || getSessionStorageBoolean(HAS_SUBMITTED_APPLICATION_FORM)) {
      window.location.assign('/register/success')
    }
  }, [onSuccess])

  const { REACT_APP_FORM_CLOSED } = process.env

  if (REACT_APP_FORM_CLOSED === 'true') return <FormClosed />

  return (
    <Box mt={{ base: '0', md: '10' }}>
      <Box
        maxWidth="768px"
        padding="10"
        pr={{ base: '10', md: '0' }}
        marginX="auto"
        height="100%"
      >
        {step > 0 && (
          <Button
            columnGap="2"
            onClick={handlePrevStep}
            data-testid="prev-step"
            fontSize="sm"
            color="orange.400"
            textTransform="uppercase"
            textDecoration="none"
            variant="link"
          >
            <ArrowBackIcon color="orange.400" mr="2" />
            Back
          </Button>
        )}
        <Heading as="h1" color="black" fontSize="3xl">
          Registration Form
        </Heading>
        <Text fontSize="md" color="gray.500" mb="7">
          Please fill in the form below to register for the program of your
          choice.
        </Text>
        <Text
          pb="5"
          letterSpacing="wider"
          fontSize="sm"
          fontWeight={600}
          color="orange.400"
          textTransform="uppercase"
          data-testid={`step-${step + 1}`}
        >
          Step {step + 1}/{stepConfig.length}: {currentStep.heading}
        </Text>
        <form onSubmit={formik.handleSubmit}>
          {step === stepConfig.length - 1 ? (
            // Render the review step
            <>
              <FormReview
                formValues={formik.values}
                paymentDetails={paymentDetails}
              />
              <Text textAlign="center" fontSize="md" color="gray.600" mt="8">
                By clicking &ldquo;Make Payment&rdquo;, you agree to our{' '}
                <a
                  href="https://dotcampus.notion.site/Terms-of-Service-for-DCLC-82648a6178cf4a93ba72404712835dce?pvs=4"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#FF5208', textDecoration: 'underline' }}
                >
                  Terms of Service
                </a>
              </Text>
            </>
          ) : (
            <Grid
              templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
              gap={6}
            >
              {currentStep.fields.map(field => {
                const fieldName = field.name as keyof FormValues

                if (field.choices) {
                  return (
                    <Select
                      key={fieldName}
                      name={fieldName}
                      label={field.label}
                      value={formik.values[fieldName]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      choices={field.choices}
                      error={
                        formik.touched[fieldName] && formik.errors[fieldName]
                      }
                      isRequired={field.isRequired}
                    />
                  )
                }

                return (
                  <Input
                    key={fieldName}
                    name={fieldName}
                    label={field.label}
                    value={formik.values[fieldName]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isRequired={field.isRequired}
                    type={field.type as InputType}
                    placeholder={field.placeholder}
                    error={
                      formik.touched[fieldName] && formik.errors[fieldName]
                    }
                  />
                )
              })}
            </Grid>
          )}
          <Box textAlign="center">
            <Text color="red.500" fontSize="sm" mt="2">
              {error}
            </Text>
            <Button
              variant="secondary"
              mt="8"
              onClick={(): void => formik.handleSubmit()}
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
              fontWeight="normal"
              textTransform="uppercase"
            >
              {step === 2 ? 'Make Payment' : 'Next'}
              {step !== 2 && <ArrowForwardIcon ml={2} />}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}
