import dataAnalysis from '../assets/data-analysis.webp'
import productDesign from '../assets/product-design.webp'
import qaManual from '../assets/qaManual.webp'
import devops from '../assets/devops.webp'
import qaAutomation from '../assets/qaAuto.webp'
import blockchain from '../assets/Blockchain.webp'
import webdev from '../assets/webdev.webp'
import dataEngineering from '../assets/data-engineering.webp'

export const courses = {
  dataAnalysis: {
    title: 'DATA ANALYSIS',
    career: 'Data Analyst',
    image: dataAnalysis,
    description: [
      'Our comprehensive curriculum covers Python, SQL, Data Analysis, Data Visualization, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced data analyst, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Intro To Data Analytics',
          contents: [
            'Introduction to Data Analytics',
            'Introduction to Programming and Computer Science 🚀',
          ],
        },
        {
          heading: 'Setting-Up',
          contents: [
            'Installing Excel',
            'Learning Environment Setup',
            'Python Installation',
            'R and RStudio Installation',
          ],
        },
        {
          heading: 'Communication',
          contents: [
            'Foundations of Communication Course',
            'Managing Expectations and Stakeholder Management Course',
          ],
        },
        {
          heading: 'Job Search',
          contents: [
            'Crafting an Effective Resume and Portfolio Course',
            'Contributing to Open Source Projects Course',
            'Understanding and Preparing for Job Interviews',
          ],
        },
        {
          heading: 'Git',
          contents: [
            'Getting Started with Version Control',
            'Git and GitHub for Beginners - Crash Course',
            'Git Branch Naming and Commit Conventions',
            'Deploying to GitHub Pages',
            'Git Knowledge Assessment',
            'Setup Your Project Repository',
          ],
        },
        {
          heading: 'Excel',
          contents: [
            'Microsoft Excel Tutorial - Full Course',
            'Data Visualization in Excel',
            'project: Full Excel - Data Analytics Project',
            'Project: Data Analytics Project 2 - Quantitative Data',
            'Project: Data Analytics Project 3 - Qualitative Data',
          ],
        },
        {
          heading: 'Python',
          contents: [
            'Introduction to Python Course',
            'Introduction to Jupyter Notebook and Jupyter Lab',
            'Introduction to Python for Data Analysis',
            'Introduction to NumPy',
            'project: Basic Python Exercises For Beginners',
            'project: Python Data Structure Exercises For Beginners',
            'project: Python Dictionary and Tuple Exercises with Solutions',
            'project: Python Date and Time Exercises with Solutions',
            'project: Python Object-Oriented Programming (OOP) Exercises',
            'project: Full Jupyter Notebook Practice Project',
            'project: Introduction to Data Analysis with Python Course',
          ],
        },
        {
          heading: 'Python-Libraries',
          contents: [
            'Introduction to NumPy and Pandas',
            'Data Cleaning and Reading SQL, CSVs, and APIs',
            'Matplotlib Crash Course',
            'project: Full Python - Data Analytics Project',
            'Web Scraping in Python',
            'project: Economic Data Analysis Project',
          ],
        },
        {
          heading: 'SQL',
          contents: [
            'Intro to SQL for Data Analysis',
            'SQL for Data Analysis Course (Coursera)',
          ],
        },
        {
          heading: 'Statistics',
          contents: [
            'Python: Statistics for Data Analysis with Python',
            'Excel: Statistics for Data Analysis with Excel',
          ],
        },
        {
          heading: 'Artificial-Intelligence',
          contents: [
            'Artificial Intelligence (AI) Tutorial',
            'AI Knowledge Assessment',
            'AI Tools for Data Analysis',
          ],
        },
        {
          heading: 'Professionalism',
          contents: [
            'Professionalism in the Workplace Course',
            'Emotional Intelligence Mastery Course',
          ],
        },
        {
          heading: 'Power-Bi',
          contents: ['Complete Power Bi Course (Bonus)'],
        },
        {
          heading: 'Capstone',
          contents: ['Capstone Project'],
        },
      ],
      description: {
        introduction:
          '<p>Gain essential skills and methodologies in Data Analysis to turn data into actionable insights for businesses and startups. You’ll get hands-on experience with <strong>Data Cleaning</strong>, <strong>Exploratory Data Analysis</strong>, and <strong>Visualization Techniques</strong> that reveal trends and inform decision-making. Our classes are engaging and practical, so don’t wait—enroll now and start your data analysis journey with us today!</p>',
        whatToLearn: [
          'Foundational concepts of Data Analysis and its impact on decision-making in various industries.',
          'Understand the importance of data collection, data cleaning, and the risks of inaccurate data handling.',
          'Learn effective techniques for data visualization and storytelling to communicate insights clearly.',
          'Gain skills in using statistical methods to uncover trends and patterns in data.',
          'Explore best practices for organizing and managing large datasets to ensure efficient analysis.',
          'Develop practical experience with data exploration techniques to identify outliers and optimize data quality.',
          'Understand the significance of business intelligence and how to leverage data to inform strategic decisions.',
        ],
        conclusion:
          "<p>This Data Analysis course dives into real-world applications and scenarios from day one. In the initial modules, you'll start analyzing datasets and uncovering valuable insights! We emphasize hands-on learning with real projects, so you can experience the impact of data analysis firsthand. What are you waiting for? Enroll now and start making data-driven decisions!</p>",
      },
    },
  },
  web: {
    title: 'WEB DEVELOPMENT',
    career: 'Web Developer',
    description: [
      'Our comprehensive curriculum covers HTML, CSS, JavaScript, Data Structures and Algorithms, Frontend frameworks, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: [
      'Computer science fundamentals',
      'Web development fundamentals',
      'Git (Version Control System)',
      'HTML',
      'CSS',
      'JavaScript',
      'Algorithms and data structures',
      'Browser extensions',
      'Software engineering fundamentals',
      'Soft-skills: Communication',
      'Soft-skills: Professionalism',
      'Resume and portfolio building',
      'Open source contribution',
    ],
  },
  frontend: {
    title: 'FRONTEND ENGINEERING',
    career: 'Frontend Engineer',
    description: [
      'Learn the core principles of software engineering, delve into ReactJS, and beyond. Craft responsive and secure web interfaces, champion web security, optimize performance, and perfect backend communication.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Introduction',
          contents: [
            'Complete Beginners Guide to Computer Programming ',
            'Introduction to Web Development',
            'Getting Started',
            'Domain Registration and Web Hosting Basics',
            'Quiz: Introduction to Computer Science and Programming',
            'Introduction to Web Development Quiz',
          ],
        },
        {
          heading: 'Git',
          contents: [
            'Getting Started with Version Control',
            'Git and GitHub for Beginners - Crash Course',
            'Git Branch Naming and Commit Conventions',
            'Deploying to GitHub Pages',
            'Git Knowledge Assessment',
            'Setup Your Project Repository',
          ],
        },
        {
          heading: 'HTML',
          contents: [
            'HTML Course',
            "Peter's Journey to Success project (HTML Only)",
            "Peter's Journey to Success project (HTML and CSS)",
            'Personal HTML Assessment Quiz',
          ],
        },
        {
          heading: 'Communication',
          contents: [
            'Foundations of Communication Course',
            'Managing Expectations and Stakeholder Management Course',
          ],
        },
        {
          heading: 'Job Search',
          contents: [
            'Crafting an Effective Resume and Portfolio Course',
            'Contributing to Open Source Projects Course',
            'Understanding and Preparing for Job Interviews',
          ],
        },
        {
          heading: 'CSS',
          contents: [
            'CSS Course (Text-based)',
            'Facebook Landing Page project',
            'LinkedIn Login Page project',
            'QR Code Component project',
            'Product Preview Card Component project',
            'Profile Card Component project',
            'Base Apparel coming soon page project',
          ],
        },
        {
          heading: 'JavaScript',
          contents: [
            'Introduction to Programming and Computer Science',
            'JavaScript Course',
            'FAQ Accordion Card project',
            'E-commerce Product Page project',
            'Tip Calculator App project',
            'Calculator App project',
            'Interactive Comments Section project',
            'URL Shortening Page project',
            'Countries App project',
          ],
        },
        {
          heading: 'Data Structure',
          contents: [
            'Introduction to Algorithms and Data Structures for Beginners (Video)',
            'Basic Data Structures project',
          ],
        },
        {
          heading: 'Algorithm',
          contents: [
            'Basic Algorithms project',
            'Intermediate Algorithms project',
            'Advanced Algorithm project',
          ],
        },
        {
          heading: 'Artificial Intelligence',
          contents: [
            'Artificial Intelligence (AI) Tutorial',
            'AI Knowledge Assessment',
            'How to Use Copilots for Coding and Debugging',
            'AI in Software Development (Bonus)',
          ],
        },
        {
          heading: 'Professionalism',
          contents: [
            'Professionalism in the Workplace Course',
            'Emotional Intelligence Mastery Course',
          ],
        },
        {
          heading: 'Testing',
          contents: [
            'Testing: Unit Testing',
            'Testing: integration Testing',
            'Testing: End-to-End (E2E) Testing',
            'Debugging for Frontend Engineering',
          ],
        },
        {
          heading: 'Design',
          contents: [
            'UI/UX Design Course for Frontend Engineers ',
            'UI/UX project: Design/Redesign your portfolio website',
          ],
        },
        {
          heading: 'Browser Extension',
          contents: [
            'How to Build a Chrome Browser Extension',
            'How to Build a Firefox Browser Extension ',
            'Browser Extension Development project',
          ],
        },
        {
          heading: 'Accessibility',
          contents: ['Web Accessibility Basics Course'],
        },
        {
          heading: 'Software Engineering',
          contents: [
            'Introduction to Software Engineering',
            'Software Development Life Cycle (SDLC) and Introduction to Agile and Scrum',
            'Software Engineering Principles',
            'Design Patterns',
            'Continuous Integration and Continuous Delivery/Deployment CI/CD (Both)',
            'Software Documentation',
          ],
        },
        {
          heading: 'ReactJS',
          contents: [
            'Introduction to ReactJS for Frontend Engineers',
            'React: Routing and Navigation with React Router',
            'React: Working with Forms',
            'Advanced State Management with Redux',
            'Testing: React App Testing with Jest and React Testing Library',
            'E-commerce Product Page project',
            'Calculator App project',
            'Interactive Comments Section project',
            'Multi-step Form project',
            'Using React Router project',
          ],
        },
        {
          heading: 'NextJs',
          contents: [
            'Introduction to Next.js for Frontend Engineers',
            'Converting React Projects to Next.js Projects',
          ],
        },
        { heading: 'Vue', contents: ['Vue.js Course for Frontend Engineers'] },
        {
          heading: 'OOP',
          contents: [
            'Object-Oriented Programming (OOP) in JavaScript Course',
            'Object-Oriented Programming (OOP) in Python Course',
            'Object-Oriented Programming (OOP) Coding project in JavaScript',
            'Object-Oriented Programming (OOP) Coding project in Python',
          ],
        },
        { heading: 'Security', contents: ['Web Security Fundamentals Course'] },
      ],
      description: {
        introduction:
          "<p>Learn industry-standard practices and advanced techniques in <strong>Frontend Engineering</strong> to build responsive, accessible, and user-centric web applications for businesses and startups. You'll master tools and frameworks like <strong>React</strong>, <strong>Next.js</strong>, and <strong>Vue</strong> to create interactive and optimized user experiences. Classes are hands-on and interactive, so don't wait—enroll and start building impactful frontends today!</p>",
        whatToLearn: [
          'Frontend Engineering Fundamentals and their impact on user experience and business outcomes.',
          'Understand the importance of writing clean, semantic HTML, CSS, and JavaScript code, and how poor structuring can affect website performance and accessibility.',
          'Discover best practices in responsive design to create interfaces that adapt smoothly to different screen sizes.',
          'Learn essential JavaScript concepts, including data structures and algorithms, to build efficient and functional applications.',
          'Gain practical experience with frameworks like React, Next.js, and Vue to streamline development and manage complex user interactions.',
          'Master browser extension development to add custom functionality and enhance user productivity.',
        ],
        conclusion:
          "<p>This <strong>Frontend Engineering</strong> course emphasizes practical experience from the very beginning. In the first few lessons, you'll already be writing HTML, CSS, and JavaScript to build real-world applications! We focus on real projects, so you'll go beyond simple exercises and create fully functional components and layouts.</p>",
      },
    },
  },
  backend: {
    title: 'BACKEND ENGINEERING',
    career: 'Backend Engineer',
    description: [
      'From mastering JavaScript/Node.js, Python, Ruby, Java and frameworks like Django, Flask, Ruby on Rails, Spring boot OR NestJS/Express, to learning database design, API development, security, scalability, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced developers, tailored to your pace and needs.',
      'Supplemental resources like coding challenges, project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Introduction',
          contents: [
            'Complete Beginners Guide to Computer Programming ',
            'Introduction to Web Development',
            'Getting Started',
            'Domain Registration and Web Hosting Basics',
            'Quiz: Introduction to Computer Science and Programming',
            'Introduction to Web Development Quiz',
          ],
        },
        {
          heading: 'Git',
          contents: [
            'Getting Started with Version Control',
            'Git and GitHub for Beginners - Crash Course',
            'Git Branch Naming and Commit Conventions',
            'Deploying to GitHub Pages',
            'Git Knowledge Assessment',
            'Setup Your Project Repository',
          ],
        },
        {
          heading: 'HTML',
          contents: [
            'HTML Course',
            "Peter's Journey to Success project (HTML Only)",
            "Peter's Journey to Success project (HTML and CSS)",
            'Personal HTML Assessment Quiz',
          ],
        },
        {
          heading: 'Communication',
          contents: [
            'Foundations of Communication Course',
            'Managing Expectations and Stakeholder Management Course',
          ],
        },
        {
          heading: 'Job Search',
          contents: [
            'Crafting an Effective Resume and Portfolio Course',
            'Contributing to Open Source Projects Course',
            'Understanding and Preparing for Job Interviews',
          ],
        },
        {
          heading: 'CSS',
          contents: [
            'CSS Course (Text-based)',
            'Facebook Landing Page project',
            'LinkedIn Login Page project',
            'QR Code Component project',
            'Product Preview Card Component project',
            'Profile Card Component project',
            'Base Apparel coming soon page project',
          ],
        },
        {
          heading: 'JavaScript',
          contents: [
            'Introduction to Programming and Computer Science',
            'JavaScript Course',
            'FAQ Accordion Card project',
            'E-commerce Product Page project',
            'Tip Calculator App project',
            'Calculator App project',
            'Interactive Comments Section project',
            'URL Shortening Page project',
            'Countries App project',
          ],
        },
        {
          heading: 'Data Structure',
          contents: [
            'Introduction to Algorithms and Data Structures for Beginners (Video)',
            'Basic Data Structures project',
          ],
        },
        {
          heading: 'Algorithm',
          contents: [
            'Basic Algorithms project',
            'Intermediate Algorithms project',
            'Advanced Algorithm project',
          ],
        },
        {
          heading: 'Artificial Intelligence',
          contents: [
            'Artificial Intelligence (AI) Tutorial',
            'AI Knowledge Assessment',
            'How to Use Copilots for Coding and Debugging',
            'AI in Software Development (Bonus)',
          ],
        },
        {
          heading: 'Professionalism',
          contents: [
            'Professionalism in the Workplace Course',
            'Emotional Intelligence Mastery Course',
          ],
        },
        {
          heading: 'Testing',
          contents: [
            'Testing: Unit Testing',
            'Testing: integration Testing',
            'Testing: End-to-End (E2E) Testing',
            'Debugging for Frontend Engineering',
          ],
        },
        {
          heading: 'Software Engineering',
          contents: [
            'Introduction to Software Engineering',
            'Software Development Life Cycle (SDLC) and Introduction to Agile and Scrum',
            'Software Engineering Principles',
            'Design Patterns',
            'Continuous Integration and Continuous Delivery/Deployment CI/CD (Both)',
            'Software Documentation',
          ],
        },
        {
          heading: 'OOP',
          contents: [
            'Object-Oriented Programming (OOP) in JavaScript Course',
            'Object-Oriented Programming (OOP) in Python Course',
            'Object-Oriented Programming (OOP) Coding project in JavaScript',
            'Object-Oriented Programming (OOP) Coding project in Python',
          ],
        },
        {
          heading: 'Database',
          contents: [
            'Introduction to Databases for Back-End Development',
            'Basic SQL',
          ],
        },
        { heading: 'Security', contents: ['Web Security Fundamentals Course'] },
        {
          heading: 'Node',
          contents: [
            'Introduction to Node.js Course',
            'How to build a REST API with Node js & Express',
            'Building CRUD REST API with Node.js, Express, and PostgreSQL',
            'Building APIs with Node.js, Express.js and MongoDB',
            'Building APIs with Node.js and Express.js',
            'Deploying a Node.js Application to Render',
            'project: Introduction to Node.js Course',
            'Advanced Node.js REST API project',
          ],
        },
        {
          heading: 'Python',
          contents: [
            'Introduction to Python Course',
            'project: Basic Python Exercises For Beginners',
            'project: Python Data Structure Exercises For Beginners',
            'project: Python Dictionary and Tuple Exercises with Solutions',
            'project: Python Date and Time Exercises with Solutions',
            'project: Python Object-Oriented Programming (OOP) Exercises',
          ],
        },
        {
          heading: 'Django',
          contents: [
            'Django Web Framework Course',
            'Django: Building with PostgreSQL DB',
            'Django REST Framework Course',
            'Django: Writing Automated Tests for Django REST APIs',
            'Django: Deployment',
            'Django App project: Building and Deploying a Simple Blog',
            'Advanced Django REST API project',
          ],
        },
        {
          heading: 'C#',
          contents: [
            'C# Programming Course for Beginners',
            'Project: Introduction to C#',
          ],
        },
        { heading: 'Docker', contents: ['Introduction to Docker Course'] },
      ],
      description: {
        introduction:
          "<p>Join us on a journey through <strong>Backend Engineering</strong>, where you'll develop the skills to design, build, and maintain robust server-side applications. Dive into frameworks like <strong>Node.js</strong>, <strong>Django</strong>, and <strong>C#</strong>, and learn to work with databases, security principles, and containerization with <strong>Docker</strong>. Our classes provide a hands-on approach to give you real-world experience right from the beginning.</p>",
        whatToLearn: [
          'Backend Engineering fundamentals and their role in scalable web applications.',
          'Explore and implement efficient data structures and algorithms for high-performance applications.',
          'Learn best practices in API development, database design, and management.',
          'Gain hands-on experience in Node.js, Django, and C# to create and maintain server-side applications.',
          'Implement robust security protocols to protect data and maintain application integrity.',
          'Containerize applications with Docker to streamline deployment and ensure reliability.',
        ],
        conclusion:
          "<p>This <strong>Backend Engineering</strong> course emphasizes a practical approach. From early on, you'll be writing code, building APIs, and working with real data structures. The curriculum covers both foundational knowledge and advanced techniques to prepare you for real-world backend development challenges.</p>",
      },
    },
  },
  productDesign: {
    title: 'PRODUCT DESIGN',
    career: 'Product Designer',
    image: productDesign,
    description: [
      'Our comprehensive curriculum covers UI/UX design, design thinking, web design, mobile app design, prototyping, user research, and more.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced designers, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Intro to Product Design',
          contents: ['Introduction to Product Design'],
        },
        {
          heading: 'User Research',
          contents: [
            'User Research and Persona Development',
            'Generate a Research Plan Documentation on the Topic You Select',
            'Create a Survey on Your Selected Topic',
            'Carry Out Interviews and Document All Information',
            'Generate Two User Personas to Define All the Participants That Were Interviewed',
          ],
        },
        {
          heading: 'Intro to Figma',
          contents: ['Introduction to Figma'],
        },
        {
          heading: 'Communication',
          contents: [
            'Foundations of Communication Course',
            'Managing Expectations and Stakeholder Management Course',
          ],
        },
        {
          heading: 'Job Search',
          contents: [
            'Crafting an Effective Resume and Portfolio Course',
            'Contributing to Open Source Projects Course',
            'Understanding and Preparing for Job Interviews',
          ],
        },
        {
          heading: 'Ideation and Conceptualization',
          contents: [
            'Ideation & Conceptualization',
            'Generate Pain Points, Reasons for Pain Points, and Solutions',
          ],
        },
        {
          heading: 'Color Style & Typography Style',
          contents: ['Color Style & Typography Style'],
        },
        {
          heading: 'Structuring User-centered Information',
          contents: [
            'User-Centered Information Design',
            'Create a Userflow Based on the Selected Topic',
            'Create a Sitemap for the Project Topic Selected',
            'Create a User Journey Map for the Topic You Have Selected',
          ],
        },
        {
          heading: 'Mobile App Design',
          contents: ['Onboarding Screens for a Mobile App Design'],
        },
        {
          heading: 'Wireframing and Prototyping',
          contents: [
            'Introduction to Wireframing and Prototyping',
            'Create Wireframes (Lo Fi, Med-Fi & Hi-Fi) for Major Screens',
            'Prototype Your High-Fidelity Designs',
          ],
        },
        {
          heading: 'Authentication Screens',
          contents: [
            'Design Login Screen',
            'Design Sign Up Screen',
            'Design Forgot Password Screens',
          ],
        },
        {
          heading: 'Home Screen Design',
          contents: ['Home Screen Design Practical Video'],
        },
        {
          heading: 'Visual Design Principles',
          contents: ['Introduction to Visual Design Principles'],
        },
        {
          heading: 'Usability Testing',
          contents: [
            'Introduction to Usability Testing',
            'Document Your Plan on Conducting a Usability Testing Session',
            'Generate 3 Tasks for Your Usability Testing Session',
            'Document All Insights from Usability Testing',
          ],
        },
        {
          heading: 'Accessibility Inclusive Design',
          contents: ['List 10 Recommendations from WCAG'],
        },
        {
          heading: 'Design Tool and Collaboration',
          contents: [
            'Master Advanced Features of Design Tool (Figma)',
            'Collaborate with Developers',
          ],
        },
        {
          heading: 'Professionalism',
          contents: [
            'Professionalism in the Workplace Course',
            'Emotional Intelligence Mastery Course',
          ],
        },
        {
          heading: 'Design Project',
          contents: ['Design Project', 'Project Presentation'],
        },
      ],
      description: {
        introduction:
          "<p>Gain essential skills and industry-standard practices in <strong>Product Design</strong> to create user-centered, visually appealing, and functional designs. You'll learn to use <strong>Figma</strong> and other collaborative design tools to craft experiences that engage users and solve real-world problems. Our interactive classes provide hands-on experience to help you dive right into the world of product design. Start your journey with us today!</p>",
        whatToLearn: [
          'Fundamentals of Product Design and its impact on user experience and business value.',
          'Master user research methods to gain valuable insights and design solutions that meet user needs.',
          'Understand effective ideation and conceptualization techniques to bring your design ideas to life.',
          'Apply visual design principles to enhance aesthetic appeal and functionality.',
          'Conduct usability testing to ensure your designs work well for your target audience.',
          'Collaborate effectively with other designers and developers to refine your design processes.',
        ],
        conclusion:
          "<p>This <strong>Product Design</strong> course emphasizes hands-on learning and practical experience. From the start, you'll be researching, prototyping, and iterating designs based on real-world scenarios. By the end, you'll have a portfolio-ready design project showcasing your journey through the design process.</p>",
      },
    },
  },
  qaManual: {
    title: 'MANUAL QA ENGINEERING',
    career: 'Manual QA Engineer',
    image: qaManual,
    description: [
      'Our detailed curriculum encompasses software testing fundamentals, test case design, test execution, defect tracking, exploratory testing, and comprehensive hands-on experience with manual testing strategies.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced manual qa testers, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Understanding QA Basics',
          contents: [
            'Introduction to Quality Assurance',
            'Quality Assurance activities',
            'Roles and responsibilities of a QA tester',
          ],
        },
        {
          heading: 'Software Development Life Cycle (SDLC)',
          contents: [
            'Phases of SDLC',
            "QA's role in each phase",
            'Waterfall vs. Agile methodologies',
          ],
        },
        {
          heading: 'Software Testing Life Cycle (STLC)',
          contents: [
            'Phases of STLC',
            'Entry and exit criteria',
            'QA documentation: Test plan, Test strategy, and Test cases',
          ],
        },
        {
          heading: 'Basics of Testing',
          contents: [
            'What is testing and its importance',
            'Principles of software testing',
            'Types of testing: Functional vs. Non-functional',
          ],
        },
        {
          heading: 'Functional Testing',
          contents: [
            'Test Levels: Unit testing, Integration testing, System testing, and User Acceptance Testing (UAT)',
            'Writing effective test cases',
          ],
        },
        {
          heading: 'Non-Functional Testing',
          contents: [
            'Performance testing, Load testing, Stress testing',
            'Security testing and Usability testing',
          ],
        },
        {
          heading: 'Static Testing Techniques',
          contents: [
            'Static Testing Techniques',
            'Benefits of static testing',
            'Practical examples and exercises',
          ],
        },
        {
          heading: 'Dynamic Testing Techniques',
          contents: [
            'Black-box testing techniques',
            'White-box testing techniques',
          ],
        },
        {
          heading: 'Writing Test Cases',
          contents: [
            'Characteristics of good test cases',
            'Test case format and templates',
            'Practice writing test cases for different scenarios',
          ],
        },
        {
          heading: 'Test Management Tools',
          contents: [
            'Introduction to test management tools (e.g., JIRA, TestRail, Quality Center)',
            'Creating and managing test cases in tools',
            'Reporting and tracking defects',
          ],
        },
        {
          heading: 'Test Execution and Reporting',
          contents: [
            'Test execution process',
            'Logging defects and issues',
            'Writing clear and concise defect reports',
          ],
        },
        {
          heading: 'Advanced Test Case Design Techniques',
          contents: ['Exploratory testing', 'Error guessing'],
        },
        {
          heading: 'Introduction to Testing Tools',
          contents: [
            'Overview of commonly used testing tools (e.g., Selenium, Postman, SoapUI)',
            'Installation and setup of tools',
          ],
        },
        {
          heading: 'Test Automation Basics (Optional)',
          contents: [
            'Basics of automation testing',
            'When to automate and when not to',
            'Simple automation scripts using Selenium',
          ],
        },
        {
          heading: 'API Testing',
          contents: [
            'Introduction to API and Web Services',
            'Using Postman for API testing',
            'Writing and executing API test cases',
          ],
        },
        {
          heading: 'Database Testing',
          contents: [
            'Basics of database testing',
            'SQL queries for testers',
            'Data validation and integrity testing',
          ],
        },
        {
          heading: 'Mobile Testing',
          contents: [
            'Introduction to mobile application testing',
            'Tools for mobile testing (e.g., Appium)',
            'Testing Mobile applications',
            'Writing test cases for mobile applications',
          ],
        },
        {
          heading: 'Performance Testing',
          contents: [
            'Basics of performance testing',
            'Tools for performance testing (e.g., JMeter)',
            'Writing and executing performance test cases',
          ],
        },
        {
          heading: 'Security Testing',
          contents: [
            'Basics of security testing',
            'Common security vulnerabilities (e.g., SQL Injection, XSS)',
            'Tools for security testing (e.g., OWASP ZAP, BurpSuite)',
          ],
        },
        {
          heading: 'Usability Testing',
          contents: [
            'Importance of usability testing',
            'Methods and techniques for usability testing',
            'Conducting usability tests and reporting',
          ],
        },
        {
          heading: 'Real-world Project Part 1',
          contents: [
            'Define project scope and objectives',
            'Test planning and requirement analysis',
            'Test case development',
          ],
        },
        {
          heading: 'Real-world Project Part 2',
          contents: [
            'Test execution',
            'Defect logging and tracking',
            'Test reporting',
          ],
        },
        {
          heading: 'ISTQB Certification Preparation',
          contents: [
            'Overview of ISTQB syllabus',
            'Sample questions and mock exams',
            'Exam strategies and tips',
          ],
        },
        {
          heading: 'Review and Wrap-up',
          contents: [
            'Review key concepts and topics',
            'Q&A sessions',
            'Final assessments and feedback',
          ],
        },
      ],
      description: {
        introduction:
          "<p>You will learn essential principles and methodologies in Manual QA Engineering to create thorough and effective testing processes for businesses and startups. You'll gain hands-on experience with <strong>Test Case Design</strong>, <strong>Defect Tracking</strong>, and <strong>Various Manual Testing Techniques</strong> that ensure software quality. Our classes are dynamic and informative, so don't wait—enroll and begin your QA journey with us today!</p>",
        whatToLearn: [
          'Manual QA Engineering Fundamentals and their significance in the software development lifecycle.',
          'Discover the importance of meticulous test planning and execution, and the potential risks of overlooking manual testing practices.',
          'Explore techniques for effective bug reporting and communication with development teams to enhance collaboration.',
          'Learn best practices for writing clear and concise test cases and documentation.',
          'Gain practical skills in exploratory testing to identify edge cases and enhance overall test coverage.',
          'Understand the importance of user acceptance testing (UAT) and how to involve stakeholders in the testing process.',
        ],
        conclusion:
          "<p>This Manual QA Engineering course focuses on real-world applications and scenarios from the outset. In the initial sections, you'll start developing and executing your own test cases! We prioritize hands-on learning with actual projects, so you can see the impact of manual testing firsthand. What are you waiting for? Enroll now!</p>",
      },
    },
  },
  qaAutomation: {
    title: 'AUTOMATION QA ENGINEERING',
    career: 'QA Automation Engineer',
    image: qaAutomation,
    description: [
      'Our robust curriculum includes automated testing principles, Selenium WebDriver, framework design, test script creation, integration with CI/CD pipelines, and practical experience with data-driven and cross-browser testing techniques.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced QA Automation Tester, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Introduction to Software Testing',
          contents: [
            'Overview of Software Testing',
            'QA roles and responsibilities',
            'Importance and principles of QA',
            'Types of testing: Manual vs. Automation',
          ],
        },
        {
          heading: 'Java Basics',
          contents: [
            'Introduction to Java',
            'Variables, Data Types, and Operators',
            'Control Statements (if-else, switch-case)',
            'Loops (for, while, do-while)',
            'Arrays',
            'Project: Write simple Java programs',
          ],
        },
        {
          heading: 'Object-Oriented Programming in Java',
          contents: [
            'Classes and Objects',
            'Methods and Constructors',
            'Inheritance, Polymorphism, Encapsulation, Abstraction',
            'Packages and Access Modifiers',
            'Project: Implement basic OOP concepts',
          ],
        },
        {
          heading: 'Advanced Java',
          contents: [
            'Exception Handling',
            'Collections Framework (List, Set, Map)',
            'File Handling',
          ],
        },
        {
          heading: 'Introduction to Selenium',
          contents: [
            'Overview of Selenium',
            'Selenium WebDriver architecture',
            'Setting up the Selenium environment',
            'Introduction to browsers and drivers',
            'Project: Create Java programs using advanced features',
          ],
        },
        {
          heading: 'Basic Selenium WebDriver',
          contents: [
            'Locating web elements (By.id, By.name, By.xpath, By.cssSelector)',
            'Basic WebDriver commands (click, sendKeys, getText)',
            'Handling different web elements (input fields, buttons, links, dropdowns)',
            'Project: Write basic Selenium scripts to interact with web pages',
          ],
        },
        {
          heading: 'Advanced WebDriver Techniques',
          contents: [
            'Handling alerts, pop-ups, and frames',
            'Working with multiple windows and tabs',
            'Implicit and explicit waits',
            'Project: Write Selenium scripts using advanced WebDriver techniques',
          ],
        },
        {
          heading: 'Data-Driven Testing',
          contents: [
            'Introduction to data-driven testing',
            'Parameterizing tests using TestNG',
            'Reading data from external sources (Excel, CSV)',
            'Project: Implement data-driven tests',
          ],
        },
        {
          heading: 'Page Object Model (POM)',
          contents: [
            'Introduction to POM',
            'Creating Page Object classes',
            'Implementing POM in Selenium tests',
            'Project: Create and use Page Object classes',
          ],
        },
        {
          heading: 'TestNG Framework',
          contents: [
            'Introduction to TestNG',
            'Annotations in TestNG (@Test, @BeforeMethod, @AfterMethod, etc.)',
            'Grouping and prioritising tests',
            'TestNG XML configuration',
            'Project: Write and run TestNG tests',
          ],
        },
        {
          heading: 'Integrating POM with TestNG',
          contents: [
            'Combining Page Object Model with TestNG',
            'Writing end-to-end test cases',
            'Managing test suites',
            'Project: Integrate POM with TestNG',
          ],
        },
        {
          heading: 'Building a Selenium Framework',
          contents: [
            'Designing a test automation framework',
            'Creating reusable utility classes',
            'Implementing logging and reporting',
            'Project: Develop a basic Selenium framework',
          ],
        },
        {
          heading: 'Enhancing the Framework',
          contents: [
            'Handling screenshots on failure',
            'Implementing data providers for complex data-driven testing',
            'Integrating with databases',
            'Project: Enhance the Selenium framework with advanced features',
          ],
        },
        {
          heading: 'Introduction to Continuous Integration (CI)',
          contents: [
            'Overview of CI/CD pipelines',
            'Introduction to Jenkins',
            'Setting up Jenkins for Selenium tests',
            'Project: Set up and configure Jenkins for CI',
          ],
        },
        {
          heading: 'Jenkins Integration',
          contents: [
            'Creating and configuring Jenkins jobs',
            'Running Selenium tests in Jenkins',
            'Generating test reports in Jenkins',
            'Project: Integrate Selenium tests with Jenkins',
          ],
        },
        {
          heading: 'Cross-Browser and Parallel Testing',
          contents: [
            'Introduction to cross-browser testing',
            'Setting up and running tests on different browsers',
            'Implementing parallel test execution',
            'Project 1: Implement Cross-Browser Testing',
            'Project 2: Implement Parallel Test Execution',
          ],
        },
        {
          heading: 'Selenium Grid',
          contents: [
            'Introduction to Selenium Grid',
            'Setting up a Selenium Grid environment',
            'Running tests on the Selenium Grid',
            'Project: Set Up and Use Selenium Grid',
          ],
        },
        {
          heading: 'Real-World Projects',
          contents: [
            'Define project scope and requirements',
            'Design and implement a complete test automation solution',
            'Execute and report test results',
            'Review and retrospective of the entire curriculum',
          ],
        },
      ],
      description: {
        introduction:
          "<p>You will learn industry best practices and cutting-edge techniques in <strong>Automation QA Engineering</strong> to create reliable, efficient, and scalable automated testing solutions for businesses and startups. You'll master tools like <strong>Selenium WebDriver</strong>, <strong>TestNG</strong>, and <strong>CI/CD integration</strong> to automate various test cases and workflows. Classes are interactive and engaging, so don't hesitate—enroll and start your journey with us today!</p>",
        whatToLearn: [
          'Automation QA Engineering Fundamentals and their business impact.',
          'Understand the importance of effective test case design, and how poor planning can lead to costly defects.',
          'Explore ways to connect automated testing with continuous integration for smooth deployment cycles.',
          'Learn techniques for designing modular and reusable test automation frameworks.',
          'Gain practical knowledge of Selenium WebDriver and the Page Object Model to optimize and maintain test scripts.',
          'Use tools like TestNG and data providers to streamline and scale your testing approach.',
        ],
        conclusion:
          "<p>This <strong>Automation QA Engineering</strong> course emphasizes hands-on experience right from the start. In the early sections, you'll already be writing and executing your first automated tests! We work with real-world scenarios, so you won't be stuck with trivial examples.</p>",
      },
    },
  },
  blockchain: {
    title: 'BLOCKCHAIN ENGINEERING',
    career: 'Blockchain Engineer',
    image: blockchain,
    description: [
      'Our comprehensive curriculum covers blockchain fundamentals, smart contract development, decentralized application (DApp) architecture, and hands-on experience with Ethereum, Solidity, and blockchain security best practices.',
      'Learn by doing with practical examples, interactive projects, and real-world case studies that make blockchain development engaging and effective.',
      'Benefit from one-on-one mentorship with experienced blockchain developers, customized to your learning pace and goals.',
      'Supplemental resources, like collaborative project forums, industry insights, and networking opportunities, provide continuous growth and foster community support.',
    ],

    curriculum: {
      accordions: [
        {
          heading: 'Blockchain Architecture',
          contents: [
            'Getting Started with Web3',
            'Getting Started with Ethereum',
            'Setting Up a Crypto Wallet',
            'Introduction to Solidity',
            'Build My First Decentralised App (dApp) using Ethereum',
            'How to Build My Own Cryptocurrency on Ethereum',
            'Building My Own NFT Contract on Ethereum',
          ],
        },
        {
          heading: 'Blockchain Primitives',
          contents: [
            'Introduction to Cryptography in Blockchain',
            'Introduction to Merkle Trees in Blockchain',
            'introduction to Consensus Mechanisms',
            'Introduction to Nodes and Clients in Blockchain',
            'Introduction to Smart Contracts and Solidity',
            'Building Your First Project with Solidity and Smart Contracts',
          ],
        },
        {
          heading: 'Ethereum Basics',
          contents: [
            'What is Ethereum',
            'Wallets in Ethereum',
            'Transactions in Ethereum',
            'Ethereum Accounts and Transactions',
            'Gas and Fees in Ethereum',
            'Introduction to Ethereum Tokens',
          ],
        },
        {
          heading: 'Solidity Fundamentals',
          contents: [
            'Setting Up a Development Environment for Solidity',
            'Introduction to Data Types and Variables in Solidity',
            'Understanding Storage and Memory in Solidity',
            'Understanding Functions in Solidity',
            'Understanding Contract Structure in Solidity',
            'Understanding Smart Contract ABI (Application Binary Interface)',
            'Understanding Events and Logs in Solidity',
            'Understanding Factory Contracts in Solidity',
          ],
        },
        {
          heading: 'Web3 Introduction and Mastery',
          contents: [
            'Introduction to dApps (Decentralized Applications)',
            'Frontend Integration Libraries for dApps',
            'Connecting Web3.js to a Smart Contract',
          ],
        },
        {
          heading: 'Layer 2',
          contents: [
            'Understanding the Blockchain Trilemma',
            'Understanding Layer 2 Solutions in Blockchain',
            'Understanding Rollups in Blockchain',
            'Understanding Optimistic Rollups in Blockchain',
            'Understanding Validity Rollups in Blockchain',
          ],
        },
      ],
      description: {
        introduction:
          "<p>Gain expertise in the rapidly evolving field of <strong>Blockchain Development</strong> and learn to build secure, decentralized applications for businesses and startups. You'll master essential blockchain technologies like <strong>Ethereum</strong>, <strong>Solidity</strong>, and <strong>smart contract development</strong>, along with critical security practices and Web3 integration. Our interactive classes and practical projects ensure an engaging learning experience. Don’t wait—join us today to begin your journey into the world of blockchain innovation!</p>",
        whatToLearn: [
          'Blockchain fundamentals and how they’re reshaping industries.',
          'Understand the critical importance of decentralization, security, and transparency in blockchain.',
          'Explore the architecture of blockchain networks, including nodes, consensus algorithms, and smart contracts.',
          'Learn techniques for developing, deploying, and securing smart contracts on Ethereum and other platforms.',
          'Gain practical knowledge of Solidity and Web3.js to interact with and manage blockchain assets.',
          'Use tools like Truffle, Ganache, and MetaMask to build and test decentralized applications (dApps) efficiently.',
        ],

        conclusion:
          '<p>This <strong>Blockchain Development</strong> course focuses on immersive, hands-on learning right from the start. In the early modules, you’ll begin writing and deploying your first smart contracts! We dive into real-world applications and industry scenarios, so you’ll work on projects that mirror real blockchain challenges, not just basic examples.</p>',
      },
    },
  },
  devOps: {
    title: 'DEVOPS/CLOUD ENGINEERING',
    career: 'DevOps/Cloud Engineer',
    image: devops,
    description: [
      'Our robust curriculum encompasses operating systems, scripting and automation, version control, virtualization and containers, networking, cloud computing, Infrastructure as Code (IaC), CI/CD practices, container orchestration, and monitoring and logging.',
      'Learn by doing, through practical examples, and hands-on exercises and projects that make learning engaging and effective.',
      'Benefit from personalized mentorship from experienced DevOps/Cloud Engineer, tailored to your pace and needs.',
      'Supplemental resources like project ideas, and community forums foster collaboration and growth.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Operating systems',
          contents: ['Linux Fundamentals', 'Linux Administration'],
        },
        {
          heading: 'Scripting and Automation',
          contents: ['Bash Scripting', 'Python Scripting'],
        },
        {
          heading: 'Version control',
          contents: ['Git basics', 'Branching strategies'],
        },
        {
          heading: 'Virtualization and Containers',
          contents: ['Virtualization', 'Docker'],
        },
        {
          heading: 'Networking',
          contents: ['Networking Basics', 'Network Tools'],
        },
        {
          heading: 'System Setup and Management',
          contents: ['Nginx Setup', 'Load Balancing', 'Firewall Configuration'],
        },
        {
          heading: 'Cloud Computing',
          contents: ['Cloud Providers', 'IAM & Security'],
        },
        {
          heading: 'Infrastructure as Code (IaC)',
          contents: ['Terraform Basics'],
        },
        {
          heading: 'Continuous Integration and Continuous Deployment (CI/CD)',
          contents: ['CI/CD Concepts', 'Github actions'],
        },
        {
          heading: 'Container Orchestration',
          contents: ['Kubernetes Basics', 'Kubernetes Networking'],
        },
        {
          heading: 'Monitoring and Logging',
          contents: ['Monitoring Tools', 'Logging'],
        },
      ],
      description: {
        introduction:
          "<p>You will learn industry best practices and cutting-edge techniques in <strong>DevOps/Cloud Engineering</strong> to streamline software development and deployment processes. You'll master essential tools and methodologies, such as <strong>cloud computing services</strong>, <strong>CI/CD pipelines</strong>, and <strong>container orchestration</strong>, ensuring that you can build and manage scalable applications efficiently. Join us today and kickstart your journey in the exciting world of DevOps!</p>",
        whatToLearn: [
          'Core principles of DevOps and their impact on software delivery.',
          'Scripting and automation techniques for improved workflow.',
          'Virtualization and containers for isolated development environments.',
          'Cloud computing services for scalable applications.',
          'Continuous Integration and Continuous Deployment (CI/CD) for streamlined delivery.',
          'Monitoring and logging strategies for application performance.',
        ],
        conclusion:
          "<p>This <strong>DevOps/Cloud Engineering</strong> course emphasizes practical experience from the outset. You'll work on real-world projects, ensuring you develop the skills necessary to thrive in modern software environments.</p>",
      },
    },
  },
  fullstack: {
    title: 'FULLSTACK ENGINEERING',
    career: 'Fullstack Engineer',
    image: webdev,
    description: [
      'Our comprehensive curriculum covers essential Fullstack development skills, including frontend frameworks like React, backend development with Node.js, and database management using MongoDB and SQL.',
      'Learn by building full-featured applications with hands-on projects that incorporate both frontend and backend development, giving you practical, end-to-end experience.',
      'Gain personalized guidance from experienced Fullstack developers, with mentorship tailored to your learning style and pace.',
      'Supplement your learning with resources like coding challenges, community forums for support, and project ideas that inspire creativity and mastery in Fullstack development.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Introduction',
          contents: [
            'Complete Beginners Guide to Computer Programming ',
            'Introduction to Web Development',
            'Getting Started',
            'Domain Registration and Web Hosting Basics',
            'Quiz: Introduction to Computer Science and Programming',
            'Introduction to Web Development Quiz',
          ],
        },
        {
          heading: 'Git',
          contents: [
            'Getting Started with Version Control',
            'Git and GitHub for Beginners - Crash Course',
            'Git Branch Naming and Commit Conventions',
            'Deploying to GitHub Pages',
            'Git Knowledge Assessment',
            'Setup Your Project Repository',
          ],
        },
        {
          heading: 'HTML',
          contents: [
            'HTML Course',
            "Peter's Journey to Success project (HTML Only)",
            "Peter's Journey to Success project (HTML and CSS)",
            'Personal HTML Assessment Quiz',
          ],
        },
        {
          heading: 'Communication',
          contents: [
            'Foundations of Communication Course',
            'Managing Expectations and Stakeholder Management Course',
          ],
        },
        {
          heading: 'Job Search',
          contents: [
            'Crafting an Effective Resume and Portfolio Course',
            'Contributing to Open Source Projects Course',
            'Understanding and Preparing for Job Interviews',
          ],
        },
        {
          heading: 'CSS',
          contents: [
            'CSS Course (Text-based)',
            'Facebook Landing Page project',
            'LinkedIn Login Page project',
            'QR Code Component project',
            'Product Preview Card Component project',
            'Profile Card Component project',
            'Base Apparel coming soon page project',
          ],
        },
        {
          heading: 'JavaScript',
          contents: [
            'Introduction to Programming and Computer Science',
            'JavaScript Course',
            'FAQ Accordion Card project',
            'E-commerce Product Page project',
            'Tip Calculator App project',
            'Calculator App project',
            'Interactive Comments Section project',
            'URL Shortening Page project',
            'Countries App project',
          ],
        },
        {
          heading: 'Data Structure',
          contents: [
            'Introduction to Algorithms and Data Structures for Beginners (Video)',
            'Basic Data Structures project',
          ],
        },
        {
          heading: 'Algorithm',
          contents: [
            'Basic Algorithms project',
            'Intermediate Algorithms project',
            'Advanced Algorithm project',
          ],
        },
        {
          heading: 'Artificial Intelligence',
          contents: [
            'Artificial Intelligence (AI) Tutorial',
            'AI Knowledge Assessment',
            'How to Use Copilots for Coding and Debugging',
            'AI in Software Development (Bonus)',
          ],
        },
        {
          heading: 'Professionalism',
          contents: [
            'Professionalism in the Workplace Course',
            'Emotional Intelligence Mastery Course',
          ],
        },
        {
          heading: 'Testing',
          contents: [
            'Testing: Unit Testing',
            'Testing: integration Testing',
            'Testing: End-to-End (E2E) Testing',
            'Debugging for Frontend Engineering',
          ],
        },
        {
          heading: 'Design',
          contents: [
            'UI/UX Design Course for Frontend Engineers ',
            'UI/UX project: Design/Redesign your portfolio website',
          ],
        },
        {
          heading: 'Browser Extension',
          contents: [
            'How to Build a Chrome Browser Extension',
            'How to Build a Firefox Browser Extension ',
            'Browser Extension Development project',
          ],
        },
        {
          heading: 'Accessibility',
          contents: ['Web Accessibility Basics Course'],
        },
        {
          heading: 'Software Engineering',
          contents: [
            'Introduction to Software Engineering',
            'Software Development Life Cycle (SDLC) and Introduction to Agile and Scrum',
            'Software Engineering Principles',
            'Design Patterns',
            'Continuous Integration and Continuous Delivery/Deployment CI/CD (Both)',
            'Software Documentation',
          ],
        },
        {
          heading: 'ReactJS',
          contents: [
            'Introduction to ReactJS for Frontend Engineers',
            'React: Routing and Navigation with React Router',
            'React: Working with Forms',
            'Advanced State Management with Redux',
            'Testing: React App Testing with Jest and React Testing Library',
            'E-commerce Product Page project',
            'Calculator App project',
            'Interactive Comments Section project',
            'Multi-step Form project',
            'Using React Router project',
          ],
        },
        {
          heading: 'NextJs',
          contents: [
            'Introduction to Next.js for Frontend Engineers',
            'Converting React Projects to Next.js Projects',
          ],
        },
        {
          heading: 'Database',
          contents: [
            'Introduction to Databases for Back-End Development',
            'Basic SQL',
          ],
        },
        { heading: 'Security', contents: ['Web Security Fundamentals Course'] },
        {
          heading: 'Node',
          contents: [
            'Introduction to Node.js Course',
            'How to build a REST API with Node js & Express',
            'Building CRUD REST API with Node.js, Express, and PostgreSQL',
            'Building APIs with Node.js, Express.js and MongoDB',
            'Building APIs with Node.js and Express.js',
            'Deploying a Node.js Application to Render',
            'project: Introduction to Node.js Course',
            'Advanced Node.js REST API project',
          ],
        },
        {
          heading: 'Python',
          contents: [
            'Introduction to Python Course',
            'project: Basic Python Exercises For Beginners',
            'project: Python Data Structure Exercises For Beginners',
            'project: Python Dictionary and Tuple Exercises with Solutions',
            'project: Python Date and Time Exercises with Solutions',
            'project: Python Object-Oriented Programming (OOP) Exercises',
          ],
        },
        {
          heading: 'Django',
          contents: [
            'Django Web Framework Course',
            'Django: Building with PostgreSQL DB',
            'Django REST Framework Course',
            'Django: Writing Automated Tests for Django REST APIs',
            'Django: Deployment',
            'Django App project: Building and Deploying a Simple Blog',
            'Advanced Django REST API project',
          ],
        },
      ],
      description: {
        introduction:
          "<p>You will learn industry-leading practices and essential techniques in <strong>Fullstack Development</strong> to build dynamic, scalable, and high-performance applications for businesses and startups. You'll gain hands-on experience with <strong>frontend frameworks</strong> like React, <strong>backend development</strong> with Node.js, and <strong>database management</strong> using SQL and NoSQL databases. Our classes are practical and engaging, so don't wait—enroll and kickstart your fullstack journey with us today!</p>",
        whatToLearn: [
          'Fullstack Development Fundamentals and their role in creating end-to-end applications.',
          'Understand the importance of designing scalable applications and how poor architecture can hinder growth.',
          'Explore the integration of frontend and backend for a seamless user experience.',
          'Learn techniques for building modular and maintainable codebases using modern frameworks.',
          'Gain practical knowledge of React for frontend and Node.js/Express for backend development to create efficient applications.',
          'Use databases like MongoDB and PostgreSQL to store and manage data effectively in your applications.',
        ],
        conclusion:
          "<p>This <strong>Fullstack Development</strong> course emphasizes hands-on experience from the very beginning. In the early sections, you'll start building your own fullstack applications! We focus on real-world projects, ensuring that you gain practical skills rather than just basic examples.</p>",
      },
    },
  },
  dataEngineering: {
    title: 'DATA ENGINEERING',
    career: 'Data Engineer',
    image: dataEngineering,
    description: [
      'Our Data Engineering curriculum covers key skills in building data pipelines, real-time data processing, and cloud-based solutions.',
      'Learn by working on hands-on projects that use tools like Apache Airflow, Kafka, Spark, and AWS/Azure for data management and orchestration.',
      'Get personalized mentorship from experienced Data Engineers to guide your learning at your own pace.',
      'Access resources like coding challenges, community support, and project ideas to help you build practical data systems.',
    ],
    curriculum: {
      accordions: [
        {
          heading: 'Data Engineering Fundamentals',
          contents: [
            'Introduction to Data Engineering',
            'Data Engineering Lifecycle',
            'Overview of Data Pipelines (ETL/ELT)',
            'Overview of Data Modeling',
            'Overview of Cloud Data Engineering',
            'Soft Skills for Data Engineers',
          ],
        },
        {
          heading: 'Linux, Git and GitHub',
          contents: [
            'What is linux?',
            'Basic Linux commands',
            'What is Git and GitHub?',
          ],
        },
        {
          heading: 'SQL',
          contents: [
            'Introduction to Databases and Data Warehousing',
            'Sql with postgres',
            'SQL Queries',
            'Window functions',
            'Introduction to Advanced SQL (Subquery & CTE)',
          ],
        },
        {
          heading: 'Python',
          contents: [
            'Introduction to python',
            'Introduction to Pandas',
            'Database interaction',
            'Everything web scrapping',
            'Web scrapping project',
            'Simple Data pipeline project',
          ],
        },
        {
          heading: 'Data Modeling',
          contents: [
            'Fundamental Concepts',
            'Dimensional Modelling and Fact Schema',
          ],
        },
        {
          heading: 'Data Build Tools (DBT)',
          contents: ['Introduction to DBT Cloud'],
        },
        {
          heading: 'Docker',
          contents: [
            'Introduction to docker for data engineers',
            'Project with python, docker and postgres',
          ],
        },
        {
          heading: 'CI/CD',
          contents: [
            'Overview CICD with DBT',
            'Deploying a simple data pipeline with CICD',
          ],
        },
        {
          heading: 'Data Integration',
          contents: ['Airbyte overview'],
        },
        {
          heading: 'Orchestration',
          contents: [
            'Introduction to Airflow',
            'Aiflow with docker',
            'project: Data pipeline with airflow and AWS EC2',
            'project: End to End project Airflow',
          ],
        },
        {
          heading: 'Cloud',
          contents: [
            'Introduction to AWS Cloud',
            'Introduction to Azure Cloud',
            'Data engineering with AWS',
            'Data engineering with Azure',
            'Azure Project 1',
            'Azure Project 2',
            'AWS Project',
          ],
        },
        {
          heading: 'Spark',
          contents: [
            'Introduction to Spark',
            'Spark Structured streaming',
            'Spark project',
          ],
        },
        {
          heading: 'Terraform',
          contents: [
            'Why Terraform?',
            'Introduction to Terraform',
            'Terraform for data engineers',
          ],
        },
        {
          heading: 'Kafka',
          contents: [
            'Apache Kafka Overview',
            'Kafka Architecture',
            'Kafka project 1',
            'Kafka project 2',
          ],
        },
        {
          heading: 'Projects',
          contents: [
            'Orchestrating ETL pipeline with airflow on AWS EC2',
            'Data engineering project playlist for Portfolio',
            'Data engineering project with AWS and airflow',
          ],
        },
      ],
      description: {
        introduction:
          "<p>In this <strong>Data Engineering</strong> course, you'll learn key techniques to build scalable data pipelines and manage data systems. You'll work with industry-standard tools like <strong>Apache Airflow</strong>, <strong>Kafka</strong>, <strong>AWS</strong>, and <strong>Azure</strong> to design efficient workflows for real-time data processing and cloud-based solutions. Our hands-on projects make learning interactive and practical, so start your data engineering journey today!</p>",
        whatToLearn: [
          'Fundamentals of Data Engineering and its role in creating efficient data pipelines.',
          'Learn how to build and manage scalable data systems using modern tools like Apache Airflow, Kafka, and Spark.',
          'Gain practical knowledge of cloud platforms like AWS and Azure for handling big data and building real-time systems.',
          'Understand how to automate workflows and manage data with tools like Docker and Terraform.',
          'Learn best practices for data storage, querying, and integration in databases like SQL and NoSQL.',
        ],
        conclusion:
          '<p>This <strong>Data Engineering</strong> course offers hands-on experience from the start. You will work on real-world projects, building data pipelines and handling large datasets, ensuring you gain the skills needed for a career in data engineering.</p>',
      },
    },
  },
}

export const learningInterests = {
  // core programmes (6+ months)
  web: 'web development',
  backend: 'backend engineering',
  frontend: 'frontend engineering',
  fullstack: 'fullstack engineering',
  dataAnalysis: 'data analysis',
  productDesign: 'product design',
  qaManual: 'manual-qa engineering',
  qaAutomation: 'automation-qa engineering',
  blockchain: 'blockchain',
  devOps: 'devops engineering',
  fullstackEngineering: 'fullstack engineering',
  dataEngineering: 'data engineering',

  // short courses (1 - 3 months)
  reactFrontend: 'react frontend',
  nodeBackend: 'node backend',
  pythonBackend: 'python backend',
  javaBackend: 'java backend',
  softwareEngineeringEssentials: 'software engineering essentials',
  excelDataAnalysis: 'data analysis with excel',
  pythonDataAnalysis: 'data analysis with python',
}
