import * as yup from 'yup'
import {
  firstName,
  lastName,
  email,
  phoneNumber,
  countryOfResidence,
  employmentStatus,
  learningPreference,
  learningInterest,
  preferredPaymentCurrency,
  paymentPlan,
  referredBy,
  referralCode,
} from '../validators'

export const StepOneValidationSchema = yup.object().shape({
  firstName,
  lastName,
  email,
  phoneNumber,
  countryOfResidence,
  employmentStatus,
})

export const StepTwoValidationSchema = yup.object().shape({
  learningPreference,
  learningInterest,
  preferredPaymentCurrency,
  paymentPlan,
  referredBy,
  referralCode,
})
