import React from 'react'
import { ApplicationForm, Metadata } from '../components'

export const ApplicationPage: React.FC = () => {
  return (
    <>
      <Metadata
        title="Register to Start Learning"
        description="Register to start learning with Dot Campus."
      />
      <ApplicationForm />
    </>
  )
}
