import { DEVELOPER_APPLICATION_REGISTRATION_URL, WHATSAPP_CONTACT_URL } from '.'
import { PricingType } from '../types'

function getRegistrationLink(
  paymentPlan: string,
  learningPreference: string,
): string {
  if (learningPreference === 'tutored')
    return `${WHATSAPP_CONTACT_URL}?text=I%20want%20to%20enquire%20about%20the%20tutored%20plan%20for%20${paymentPlan}`
  return `${DEVELOPER_APPLICATION_REGISTRATION_URL}?paymentPlan=${paymentPlan}&learningPreference=${learningPreference}`
}

const benefits = {
  guidedLearning: [
    'Job updates and support',
    'Engaging learning community',
    'Structured learning guide and resources',
    'Skill-sharpening assignments and projects',
    'Accountability groups and progress tracking',
    'Suitable for self-paced learners looking to up-skill',
  ],
  mentorship: [
    '+ "Self-Paced Learning" plan',
    'Career guidance and support',
    'Project reviews and feedback',
    'Mentorship from an industry expert',
    '1 - 3 hours of one-to-one mentorship sessions weekly',
    'Suitable for learners looking to start a career in tech',
  ],
  tutored: [
    '+ "Self-Paced Learning" plan',
    'Career guidance and support',
    'Project reviews and feedback',
    'Tutoring from trained professionals',
    '6 - 15 hours of live tutorial sessions weekly',
    'Suitable for all who prefer tutor-led learning',
  ],
}

export const valueBasedPricing: {
  monthly: PricingType[]
  quarterly: PricingType[]
  upfront: PricingType[]
} = {
  monthly: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦10,500',
        dollar: '$7.5',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('monthly', 'guidedLearning'),
      duration: 'Per Month',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦35,000',
        dollar: '$25',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('monthly', 'mentorship'),
      duration: 'Per Month',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('monthly', 'tutored'),
      duration: 'Per Month',
    },
  ],
  quarterly: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦27,000',
        dollar: '$19.5',
      },
      savings: {
        naira: 'Save up to ₦4,500',
        dollar: 'Save up to $3.5',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('quarterly', 'guidedLearning'),
      duration: 'Covers 3 Months',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦98,700',
        dollar: '$70.5',
      },
      savings: {
        naira: 'Save up to ₦6,300',
        dollar: 'Save up to $4.5',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('quarterly', 'mentorship'),
      duration: 'Covers 3 Months',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: ' . ',
        dollar: ' . ',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('quarterly', 'tutored'),
      duration: 'Covers 3 Months',
    },
  ],
  upfront: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦45,000',
        dollar: '$32.5',
      },
      savings: {
        naira: 'Save up to ₦18,000',
        dollar: 'Save up to $12.5',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('upfront', 'guidedLearning'),
      duration: 'Covers 6 Months',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦184,800',
        dollar: '$132',
      },
      savings: {
        naira: 'Save up to ₦25,200',
        dollar: 'Save up to $18',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('upfront', 'mentorship'),
      duration: 'Covers 6 Months',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: ' . ',
        dollar: ' . ',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('upfront', 'tutored'),
      duration: 'Covers 6 Months',
    },
  ],
}

export const valueBasedPricingFullstack: {
  monthly: PricingType[]
  quarterly: PricingType[]
  upfront: PricingType[]
} = {
  monthly: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦10,500',
        dollar: '$7.5',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('monthly', 'guidedLearning'),
      duration: 'Per Month',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦35,000',
        dollar: '$25',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('monthly', 'mentorship'),
      duration: 'Per Month',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: '',
        dollar: '',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('monthly', 'tutored'),
      duration: 'Per Month',
    },
  ],
  quarterly: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦27,000',
        dollar: '$19.5',
      },
      savings: {
        naira: 'Save up to ₦4,500',
        dollar: 'Save up to $3.5',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('quarterly', 'guidedLearning'),
      duration: 'Covers 3 Months',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦98,700',
        dollar: '$70.5',
      },
      savings: {
        naira: 'Save up to ₦6,300',
        dollar: 'Save up to $4.5',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('quarterly', 'mentorship'),
      duration: 'Covers 3 Months',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: ' . ',
        dollar: ' . ',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('quarterly', 'tutored'),
      duration: 'Covers 3 Months',
    },
  ],
  upfront: [
    {
      title: 'Self-Paced Learning',
      price: {
        naira: '₦90,000',
        dollar: '$64.5',
      },
      savings: {
        naira: 'Save up to ₦36,000',
        dollar: 'Save up to $25.5',
      },
      benefits: benefits.guidedLearning,
      link: getRegistrationLink('upfront', 'guidedLearning'),
      duration: 'Covers 1 year',
    },
    {
      title: 'Mentorship',
      price: {
        naira: '₦369,600',
        dollar: '$264',
      },
      savings: {
        naira: 'Save up to ₦50,400',
        dollar: 'Save up to $36',
      },
      benefits: benefits.mentorship,
      link: getRegistrationLink('upfront', 'mentorship'),
      duration: 'Covers 1 year',
      isRecommended: true,
    },
    {
      title: 'Adaptive Tutoring',
      price: {
        naira: 'Custom',
        dollar: 'Custom',
      },
      savings: {
        naira: ' . ',
        dollar: ' . ',
      },
      benefits: benefits.tutored,
      link: getRegistrationLink('upfront', 'tutored'),
      duration: 'Covers 1 year',
    },
  ],
}
