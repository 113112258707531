import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'

import FullColourLogo from '../../assets/full-colour-logo.png'
import {
  APPLICATION_MAX_WIDTH,
  siteNavLinks,
  SUPPORT_EMAIL,
} from '../../constants'
import { Link } from './Link'
import linkedInIcon from '../../assets/linkedin-icon.svg'
import twitterIcon from '../../assets/x-icon.svg'
import githublogo from '../../assets/GitHub_Logo_White.webp'
import facebookIcon from '../../assets/facebook-icon.svg'
import youtubeIcon from '../../assets/youtube-icon.svg'
// import facebookIcon from '../../assets/icons/facebook.png'
// import whatsappIcon from '../../assets/icons/whatsapp.png'

export const Footer: React.FC = () => {
  return (
    <Box backgroundColor="black" width="100%">
      <Flex
        maxWidth={APPLICATION_MAX_WIDTH}
        width="100%"
        marginX="auto"
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
        justifyContent="space-between"
        padding="10"
        data-testid="footer"
        mb="16"
      >
        <Box width={{ base: '100%', md: '45%', lg: '35%' }} mt="5">
          <Image
            src={FullColourLogo}
            mb="3"
            alt="Dot Campus Logo"
            width="387px"
          />
          <Flex align="center" my="5">
            <Text fontSize="md" color="gray.200">
              An official Technology Partner with
            </Text>
            <Image src={githublogo} width="100px" alt="Github logo" />
          </Flex>
          <Text fontSize="md" color="gray.200">
            We are committed to empowering individuals across the world,
            especially in Africa by providing accessible and effective learning
            opportunities, guidance, mentorship, and resources, to enhance their
            prospects for personal and professional growth.
          </Text>
        </Box>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          width={{ base: '100%', md: '45%', lg: '35%' }}
          mt="5"
        >
          <Box width="50%">
            <Heading as="h3" fontSize="xl" mb="3" color="gray.200">
              Quick Links
            </Heading>
            {siteNavLinks.map(link => (
              <Link
                key={link.name}
                to={link.url}
                isExternal={link.isExternal}
                variant="muted"
                fontSize="md"
                marginBottom="3"
                display="block"
              >
                {link.name}
              </Link>
            ))}
          </Box>
          <Box width={{ base: '100%', md: '50%' }} mt={{ base: '5', md: '0' }}>
            <Heading as="h3" fontSize="xl" mb="3" color="gray.200">
              Connect With Us
            </Heading>
            <Link
              to="tel:+2348189805679"
              variant="muted"
              fontSize="md"
              mb="3"
              display="block"
            >
              +2348189805679
            </Link>
            <Link
              to="tel:+2349126336544"
              variant="muted"
              fontSize="md"
              mb="3"
              display="block"
            >
              +2349126336544
            </Link>
            <Link
              to={`mail:${SUPPORT_EMAIL}`}
              variant="muted"
              fontSize="md"
              display="block"
              mb="3"
            >
              {SUPPORT_EMAIL}
            </Link>
            <Flex>
              <Link
                to="https://www.linkedin.com/company/77021112/"
                isExternal
                variant="muted"
                fontSize="md"
                mr="3"
              >
                <Image src={linkedInIcon} alt="LinkedIn" />
              </Link>
              <Link
                to="https://twitter.com/dotcampushq"
                isExternal
                variant="muted"
                fontSize="md"
                mr="3"
              >
                <Image src={twitterIcon} alt="x (twitter)" />
              </Link>

              <Link
                to="https://www.facebook.com/people/Dot-Campus-Africa/61556090831382/"
                isExternal
                variant="muted"
                fontSize="md"
                mr="3"
                borderRadius="full"
                border="1px solid white"
                p="1.5"
              >
                <Image src={facebookIcon} alt="Facebook" />
              </Link>
              <Link
                to="https://www.youtube.com/@DotCampusAfrica"
                isExternal
                variant="muted"
                fontSize="md"
                mr="3"
                borderRadius="full"
                border="1px solid white"
                p="1.5"
              >
                <Image src={youtubeIcon} alt="YouTube" />
              </Link>
            </Flex>
          </Box>
        </Flex>
      </Flex>

      <Text
        fontSize="sm"
        color="gray.400"
        borderTop="1px solid"
        borderColor="gray.200"
        textAlign="center"
        padding="5"
      >
        © {new Date().getFullYear()} Dot Campus Africa LLC. All rights reserved
      </Text>
    </Box>
  )
}
