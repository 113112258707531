export const employmentOptions = [
  { label: 'Select status', value: '' },
  { label: 'Student', value: 'student' },
  { label: 'Employed', value: 'employed' },
  { label: 'Unemployed', value: 'unemployed' },
]

export const learningPreferences = [
  { label: 'Select learning preference', value: '' },
  {
    label: 'Self-Paced Learning',
    value: 'guidedLearning',
  },
  {
    label: 'Mentorship',
    value: 'mentorship',
  },
]

export const paymentPlans = [
  { label: 'Select payment plans', value: '' },
  { label: 'Upfront', value: 'upfront' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Monthly', value: 'monthly' },
]

export const paymentCurrencies = [
  { label: 'Select payment currency', value: '' },
  { label: 'NGN', value: 'ngn' },
  { label: 'USD', value: 'usd' },
]

export const referredByOptions = [
  { label: 'Select answer', value: '' },
  { label: 'WhatsApp', value: 'whatsApp' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'YouTube', value: 'youTube' },
  { label: 'Google', value: 'google' },
  { label: 'X (Twitter)', value: 'twitter' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'LinkedIn', value: 'linkedIn' },
  { label: 'Friend', value: 'friend' },
  { label: 'Other', value: 'other' },
]