import React from 'react'
import { Metadata } from '../components'
import { FormSuccess } from '../components/application/FormSuccess'

export const RegistrationSuccessPage: React.FC = () => {
  sessionStorage.clear()
  return (
    <>
      <Metadata
        title="Registration Successful"
        description="Registration was successful. Please check your email for the next steps. Thank you!"
      />
      <FormSuccess />
    </>
  )
}
